.full-rounded{
    height: 40px !important;
    width: 40px !important;
    font-size: 16px;
    @include flex-center;
    border-radius: 50%;
    background-color: $mainBlue;
    color: white;
    margin-right: 50px;
}

// smooth transition from app-bg color to navbar bg-color
.navLinks-cont{
    background: linear-gradient(90deg, rgba(255,255,255,0.25) 15%, white);
    height: $navHeight,
}

.search-Icon{
    margin-right: 50px;
    cursor: pointer;
}
.user-avatar{
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center !important;
    padding-left: 1.2px;
    padding-bottom: 1.2px;
}
.responsive-nav-cont{
    width: 100% !important;
    height: 75px;
}
.responsive-logo{
    margin-left: 35px !important;
}
.icons-cont{
    background: linear-gradient(90deg, rgba(255,255,255,0.15) 50%, white);
/* Gray F4 */

    padding-right: 35px;
border-bottom: 1px solid #F0F0F0 !important;
height: 100%;
width: 75%;
justify-content: flex-end;
}