.files-page {
  max-width: 630px;
  height: 100%;
  padding: 36px 36px !important;
  margin: 0 auto;
}

.files-page-card {
  margin-bottom: 1.5rem;
}

.files-page-card-title {
  margin-bottom: 1rem;
}

.files-page-card-content.drag-and-drop {
  width: 100%;
  height: 140px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.files-page-card-content input {
  border: 1px solid #e4e4e4 !important;
  padding: 16.5px 20px !important;
  border-radius: 4px;
}

.files-page-card-content-checkboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.files-page-card-content-checkboxes .MuiFormControlLabel-root {
  width: calc(100% / 3) !important;
  margin: 0 !important;
}

.files-page-card-add-button {
  padding-bottom: 36px;
  display: flex;
  justify-content: space-between;
}

// .files-page-drag-and-drop {
//   word-break: break-all !important;
// }

.headingRowFiles th {
  padding-right: 35px !important;
}

.search-field {
  margin-right: 30px;
  margin-left: 0px;
}

.reset-filters {
  margin-left: 30px;
}

.filesPage {
  align-self: flex-start;
}

.pdf-canvas canvas {
  width: 100% !important;
}

// for showing pdf file on mobile phone
// .pdf-canvas canvas {
//   width: 250% !important;
// }

.pg-viewer-wrapper .document-container {
  width: 100% !important;
}

.document-container {
  background-color: transparent !important;
}

.files-page-categories {
  display: flex;
  flex-wrap: wrap;
}

.files-page-categories-responsive {
  display: flex;
  flex-wrap: wrap;
}

.files-page-categories > :first-child {
  padding: 16px 16px 16px 0px !important;
}

.files-page-categories-responsive > :first-child {
  padding: 0px !important;
}

.files-page-categories-category {
  width: calc(100% / 6) !important;
  padding: 16px;
  padding-left: 0px;
}

.files-page-categories-category-responsive {
  width: calc(100% / 3) !important;
  padding: 0px;
  margin-top: 10px;
}

.files-page-category-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // padding: 18px 72px;
  // gap: 10px;
  // width: 100%;
  // height: 100%;
  padding: 12px 52px;
  gap: 5px;
  width: 100%;
  height: 100%;
  background: #226cb0;
  border-radius: 9px;
  @include media-below($bp-xl) {
    width: 85px;
  }
}

.add-file-message {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  margin: 16px 0;
}

.add-file-message p {
  color: green;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 2px;
}

.files-view-page-delete-btn {
  margin: 0 auto !important;
}

.files-note {
  width: 100%;
  resize: none;
  padding: 10px;
  height: 100px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  outline: none;
  font-size: 18px;
}

.files-custom-modal {
  width: 500px !important;
  @include media-below($bp-xl) {
    width: 250px !important;
  }
}

.files-viewer-custom-modal {
  display: flex;
  flex-direction: column;
  @include media-below($bp-xl) {
    width: 95% !important;
    height: 90% !important;
    padding: 0px;
    margin: 0px;
  }
}

.files-edit-note {
  margin-bottom: 12px;
}

.files-edit-note textarea {
  width: 100%;
}

.files-custom-modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.files-custom-modal-buttons button {
  margin-left: 8px;
}

.pg-viewer-wrapper .document-container {
  margin: 0 !important;
}

.page-navigation-buttons {
  display: flex;
  flex-wrap: wrap;
}

.page-navigation-buttons > button {
  margin: 0 !important;
  margin-right: 12px !important;
}

.files-file-routing {
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid #ffff;
  border-radius: 10px;
  padding-left: 10px;
  padding: 4px 10px;
}

.files-file-routing:hover {
  background-color: #f0f0f0;
}

.add-categories-files {
  display: flex;
}

.add-categories-files > * {
  margin-left: 6px !important;
}

.files-page-card-input {
  display: flex;
}

.add-category-input input,
.files-page-card-input input {
  width: 100%;
  border: 1px solid #e4e4e4 !important;
  padding: 16.5px 20px !important;
  border-radius: 4px;
  outline: none;
}

.files-page-card-input > button {
  margin-left: 6px;
}

.files-category-custom-modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  @include media-below($bp-xl) {
    justify-content: center;
  }
}

.files-category-custom-modal-buttons button {
  margin-left: 8px;
}

.files-button-responsive {
  gap: 5px;
  width: 85px;
  height: 31px;
}

.add-categories-files > button:first-child {
  margin-left: 0px !important;
}

.file-viewer-close-btn {
  width: 100px;
  align-self: flex-end;
  margin-top: 20px;
}

.reset-filter-responsive {
  align-self: flex-end;
  padding: 0px;
  margin-top: 15px;
  text-decoration: underline;
  cursor: pointer;
}
