.c-icon-button {
  @include flex-center;
  @include outline-none;
  @include button-clear;
  user-select: none;
  cursor: pointer;
}
.td-btn {
  width: 34px !important;
  height: 34px !important;
  min-width: none !important;
}
.td-btn.inactive {
  background: #ffeaee !important;
  color: #d72228 !important;
  border-color: #d72228 !important;
}

.userPageBtn.activeEnable {
  // background-color: rgba(100,255,100,0.75) !important;
}

.userPageBtn.deactiveEnable {
  background: #ffeaee !important;
  color: #d72228 !important;
  border-color: #d72228 !important;
}
