body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-anchor: none;
}

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  font-size: 16px;

  @include media-below($bp-xxl) {
    font-size: 14px;
  }

  @include media-below($bp-xs) {
    font-size: 13px;
  }

  @include media-below($bp-xxs) {
    font-size: 10.5px;
  }
}

body{
  background-color: $grayFD !important;
  overflow-x: hidden;
}
html,
body,
#root {
  @include flex-column;
  flex: 1 0 auto;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

ul {
  list-style: none;
  padding: 0;
}

.S9gUrf-YoZ4jf {
  @include flex-center;
  width: 100% !important;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.S9gUrf-YoZ4jf > * {
  margin: auto;
  padding: 0 !important;
  margin: 0 !important;
}