@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
button,
input,
textarea,
p,
blockquote,
th,
td {
  font-family: $font-family;
}

p {
  vertical-align: middle;
  display: inline-block;
  word-break: break-word;
  font-size: pxToRem(16px);
  line-height: 1.5;

  @include media-below($bp-md) {
    font-size: pxToRemMd(16px);
  }
}

a {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

.text-end {
  width: 100%;
  text-align: end;
}

h5 {
  // imported from figma
  //styleName: Heading;
  font-family: Source Sans Pro !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: 0.02em !important;
  text-align: center !important;
}

.text-black {
  color: $mainBlack;
}

.text-blue {
  color: $mainBlue;
}

.text-grey9d {
  color: $grey-9D;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.page-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 32px;
  /* identical to box height, or 89% */

  letter-spacing: 0.02em;

  /* Main Black */

  color: #272727;
  @include media-below($bp-xl) {
    font-size: 18px !important;
  }
}
.highlighted{
  background: $mainBlue;
  color: #fff;
}
