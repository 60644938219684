.l-page {
  @include flex-column;
  flex: 1 1 auto;
  // padding-bottom: 7rem;
  position: relative;
  @include media-below($bp-xl) {
    // padding-bottom: 4rem;
  }
}

.l-section {
  padding: 0 3.25rem;

  @include media-below($bp-xl) {
    padding: 0;
  }
}
.pl-144 {
  padding-left: 144px;
  padding-right: 72px;

  @include media-below($bp-xl) {
    padding: 20px 36px !important;
  }
}
.pt-36px {
  padding-top: 36px !important;
}
.ml-20px {
  margin-left: 30px;
  @include media-below($bp-xl) {
    margin-left: 15px;
  }
}
.divider{
  display: flex;
  justify-content: space-between;
}
