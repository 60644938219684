
.applicant-cv-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  background: #226cb0;
  border-radius: 9px;
  width: 212px;
  height: 51px;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}

.applicant-ads-back-button {
  margin-right: 36px;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #226cb0;
  cursor: pointer;
}

@media only screen and (max-width: 540px) {
  .applicant-cv-button {
    padding: 10px 52px;
    gap: 7px;
    border-radius: 7px;
    width: 180px;
    height: 40px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
}

@media only screen and (max-width: 361px) {
  .applicant-ads-back-button {
    font-size: 14px;
    line-height: 18px;
    margin-right: 0;
  }
  .applicant-cv-button {
    padding: 18px 45px;
    width: 158px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
  }
}
