.candidate-btn {
  margin-left: 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #226cb0;
  text-transform: none;
}

.invite-btn {
  padding: 18px 72px;
  gap: 10px;
  height: 51px;
  width: 190px;
  border-radius: 9px;
  display: flex;
  align-self: flex-end;
  margin-left: 60px;
  margin-bottom: 36px;
}

.candidates-image {
  height: 14px;
  width: 16px;
  margin-left: 10px;
}

.ads-search-field-responsive2 {
  border: 1px solid #226cb0;
  border-radius: 10px;
  padding: 15px;
  background-color: white;
  position: absolute;
  top: 125px;
  right: 35px;
  z-index: 1000;
  &.smaller {
    min-width: 250px !important;
  }
}

.ads-search-field-responsive::placeholder {
  font-size: 1rem;
  color: #9d9d9d;
  font-style: italic;
}

.ads-search-field {
  min-width: 700px !important;
  border: 1px solid #226cb0;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  position: absolute;
  top: 112px;
  right: 72px;
  z-index: 1000;
}

.ads-search-field::placeholder {
  font-size: 1rem;
  color: #9d9d9d;
  font-style: italic;
}

.candidate-search-field {
  width: 816px;
  border: 1px solid #226cb0;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  // position: absolute;
  // top: 112px;
  // right: 70px;
  z-index: 1000;
}

.candidate-search-field::placeholder {
  font-size: 1rem;
  color: #9d9d9d;
  font-style: italic;
}

.candidates-pagination {
  margin-top: 20px;
  align-self: center;
  padding-bottom: 20px;
}
