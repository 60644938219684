.steps-cont {
  @include flex-center;
  margin-top: 10px;
  margin-bottom: 20px;

  .line-btw {
    width: 36px;
    height: 1px;
    background-color: #e4e4e4 !important;
  }
  .step {
    width: 36px;
    height: 36px;
    @include flex-center;

    &.disallowed {
      background: #f4f4f4 !important;
      border: 1px solid #e4e4e4 !important;
      border-radius: 7px !important;
    }

    &.current {
      background: #ffffff !important;
      /* Gray E4 */

      border: 1px solid #e4e4e4 !important;
      border-radius: 7px !important;
    }

    &.done {
      background: #e8f7ff !important;
      /* Main Blue */

      border: 1px solid #226cb0 !important;
      border-radius: 7px !important;
    }
  }
}
