@import './assets/styles/variables';
@import './assets/styles/mixins';
@import './assets/styles/functions';
@import './assets/styles/typography';
@import './assets/styles/base';
@import './assets/styles/reset';
@import './assets/styles/components/button';
@import './assets/styles/components/icon-button';
@import './assets/styles/components/app-button';
@import './assets/styles/components/usersPage';
@import './assets/styles/components/candidatesPage';
@import './assets/styles/components/candidatePage';
@import './assets/styles/components/loader';
@import './assets/styles/components/radio';
@import './assets/styles/components/modal';
@import './assets/styles/components/auth-card';
@import './assets/styles/components/user-profile';
@import './assets/styles/components/auth';
@import './assets/styles/components/login';
@import './assets/styles/components/register';
@import './assets/styles/components/selectionProcessPage';
@import './assets/styles/components/login-card';
@import './assets/styles/components/forgot-password';
@import './assets/styles/components/input';
@import './assets/styles/components/error-page';
@import './assets/styles/components/rules';
@import './assets/styles/components/nav';
@import './assets/styles/components/ads';
@import './assets/styles/components/day-details-component';
@import './assets/styles/layout';
@import './assets/styles/overwrite';
@import './assets/styles/utility';
@import './assets/styles/components/patterns';
@import './assets/styles/components/files';


.flex-center{
    @include flex-center;
}
.flex-col{
    flex-direction: column !important;
}
.fullwidth{
    width: 100% !important;
}
.h-withHeader{
    height: calc(100vh - $navHeight);
    // remove css line below, just for showcase
}

// ================= mui overrides
.css-122f4x8-MuiToolbar-root {
    padding: 0 !important;
}