.f-1 {
  flex: 1;
}
.usersTable {
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  @include media-below($bp-xl) {
    overflow-x: auto !important;
  }
}
.table-cont {
  display: flex;
  flex-direction: column;
  @include media-below($bp-xl) {
    overflow-x: auto !important;
  }
}
.usersTable .headingRow {
  background-color: $mainBlueLight;
}
.usersTable .headingRow th {
  font-weight: 600 !important;
}
.usersTable tr {
  border: 1px solid #f4f4f4;
}
.usersTable td,
.usersTable th {
  width: 20% !important;
  height: 60px;
  padding-left: 35px;
}
.usersTable > tbody > tr > td:last-of-type {
  display: flex !important;
  flex-direction: row !important;
  // justify-content: space-between;
  align-items: center;
  gap: 9px;
}
.inviteBtn {
  text-transform: capitalize;
  padding: 10px 40px !important;
  margin-left: 20px;
  font-size: 16px !important;
}
.secondaryRow:hover {
  background-color: $mainBlueLight;
}
.cvLink {
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  //   line-height: 15px;
  /* identical to box height */
  text-decoration-line: underline;
  /* Main Blue */
  color: #226cb0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   text-align: center;
  //   padding-left: 35px;
}
.dialog-subtitle {
  position: relative;
  top: 2.5px;
  color: $mainBlue;
  margin-right: 100px;
}

// users sccs

.f-1 {
  flex: 1;
}
.usersTable-users {
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  @include media-below($bp-xl) {
    overflow-x: auto !important;
  }
}
.table-cont {
  @include media-below($bp-xl) {
    overflow-x: auto !important;
  }
}
.usersTable-users {
  &.normal {
    min-width: 960px;

    td,
    th {
      width: calc(100% / 4) !important;
      height: 60px;
      padding-left: 35px;
    }
  }
  &.mini {
    width: 800px;
    td,
    th {
      width: calc(100% / 3) !important;
      height: 60px;
      padding-left: 35px;
    }
  }
}
.profession {
  width: 100%;
  margin: 0 !important;
}
.usersTable-users .headingRow {
  background-color: $mainBlueLight;
}
.usersTable-users .headingRow th {
  font-weight: 600 !important;
}
.usersTable-users tr {
  border: 1px solid #f4f4f4;
}
.usersTable-users.normal > tbody > tr > td:last-of-type {
  display: flex !important;
  flex-direction: row !important;
  // justify-content: space-between;
  align-items: center;
  gap: 9px;
}
.docs-name{
  color: $mainBlue;
  cursor: pointer;
}

.user-details-middle {
  display: flex;
  margin-top: 1rem;
}

.user-details-middle > div {
  flex: 1;
}

.user-details-middle-checkboxes .MuiFormGroup-root {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
}

.user-details-middle-checkboxes .MuiFormGroup-root .MuiFormControlLabel-root {
  width: calc(100% / 3) !important;
  margin: 0 !important;
}