.user-view {
  position: absolute;
  width: 342px;
  height: 317px;
  background-color: red;
  top: calc($navHeight + 1rem);
  z-index: 1000;
  right: -360px;
  background: #ffffff;
  /* Main Shadow 12% */
  transition: 0.35s;
  opacity: 0.25;

  box-shadow: 4px 4px 18px rgba(29, 29, 29, 0.12);
  border-radius: 18px;
  animation: userView 0.35s !important;
}

.change-language {
  display: flex;
}

.change-language > * {
  margin: 18px 4.5px 18px 4.5px !important;
  width: 54px !important;
  padding: 9px 36px !important;
  gap: 9px !important;
}

.change-language-active-button {
  background-color: $mainBlueLight !important;
}

.user-view.active {
  right: 1rem;
  opacity: 1;
}

@keyframes userView {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
