
.custom-modal {
  padding: 36px !important;
  border: none !important;
  border-radius: 18px;
}

.add-pattern-modal {
  width: 512px;
  min-height: 618px;
}

.add-pattern-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.add-pattern-modal-header-title {
  display: flex;
  align-items: center;
}

.add-pattern-modal-header-title > * {
  margin-right: 4px;
}

.add-pattern-modal-header-title-image img {
  width: 18px;
  height: 18px;
}

.add-pattern-modal-header-title-title p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #272727;
}

.add-pattern-modal-header-title-sub sub {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: $mainBlue;
}

.add-pattern-modal-header-close img {
  width: 9px;
  height: 10.5px;
  cursor: pointer;
}

.add-pattern-modal-form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.add-pattern-modal-form-control .tox {
  height: 300px !important;
}

.add-pattern-modal-form-control label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 4.5px;
}

.add-pattern-modal-form-control input,
.add-pattern-modal-form-control select,
.add-pattern-modal-form-control textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  outline: none;
}

.add-pattern-modal-form-control textarea {
  resize: none;
}

.add-pattern-modal-form-control input[type="submit"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  background: #226cb0;
  color: white;
  border-radius: 9px;
  cursor: pointer;
}

.custom-filter-drawer-header-container {
  display: flex;
  justify-content: space-between;
}

.custom-filter-drawer-header {
  display: flex;
  align-items: center;
}

.custom-filter-drawer-header-close {
  cursor: pointer;
}

.custom-filter-drawer-header > * {
  margin-right: 0.25rem;
}

.custom-filter-drawer-header img {
  width: 18px;
  height: 15.75px;
}

.custom-filter-drawer-header sub {
  color: #226cb0;
}

.custom-filter-drawer-content {
  margin-top: 18px !important;
  box-sizing: border-box;
}

.custom-drawer-sub-card {
  margin-bottom: 18px;
}

.custom-drawer-sub-card-label {
  margin-bottom: 10px;
}

.custom-drawer-sub-card-label p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
}

.custom-drawer-sub-card-content-sub {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100% !important;
}

.custom-drawer-sub-card-content-sub input {
  width: 100% !important;
}

.custom-drawer-sub-card-content-input-1::-webkit-calendar-picker-indicator,
.custom-drawer-sub-card-content-input-2::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.custom-drawer-sub-card-content-sub-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 4.5px;
}

.custom-drawer-sub-card-content input[type="date"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
}

.custom-drawer-submit {
  margin-top: 18px;
  padding-bottom: 18px;
}

.custom-drawer-submit > * {
  width: 100%;
}
