h1,
h3 {
  margin: 0;
  padding: 0;
}

.add-ad-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  width: 201px;
  height: 51px;
  background: #226cb0;
  border-radius: 9px;
  margin-top: 9px;
  @include media-below($bp-xl) {
    width: 147px;
  }
}

.ad-filters-header-container {
  display: flex;
  justify-content: space-between;
}

.ad-filters-header {
  display: flex;
  align-items: center;
}

.ad-filters-header-close {
  cursor: pointer;
}

.ad-filters-header > * {
  margin-right: 0.25rem;
}

.ad-filters-header img {
  width: 18px;
  height: 15.75px;
}

.ad-filters-header sub {
  color: #226cb0;
}

.ad-filters-technologies {
  margin-top: 18px;
}

.uploadCV-input-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadCV-input-sub-container img {
  width: 22px;
  height: 22px;
}

.uploadCV-input-sub-container p {
  width: 332px;
  height: 40px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.11px;
  letter-spacing: 0em;
  text-align: center;
}