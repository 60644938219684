h1,
h3 {
  @include media-below($bp-xl) {
    font-size: 18px;
  }
}

.grey {
  color: #e4e4e4;
}

.interview-btn {
  width: 220px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  text-align: left;
  padding-left: 20px;
}
