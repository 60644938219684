.hr{
    height: 2px;
    background-color: $grey-12;
    width: 115px;
    margin: 5px 10px;
}
.hr-mid{
    padding: 2.5px 7.5px;
}
.vr{
    height: 20px;
    position: relative;
    top: 2.5px;
    margin-left: 5px;
    margin-right: 5px;
    width: 2px;
    background: $mainBlue;
}